<template>
  <div class="status-cards mb-l">
    <div style="display: grid; grid-template-columns: repeat(3, minmax(0, 1fr)); gap: var(--margin-m);">
      <Statuscard :title="$t('inventory.assortment')"
      :ist="inventoryVenueWineState.checkedWines.total + ' ' + $t('inventory.products')"
      :soll="inventoryVenueWineState.totalWines + ' ' + $t('inventory.products')"
      :performance="formatNumber(inventoryVenueWineState.checkedWines.total -  inventoryVenueWineState.totalWines) + ' ' + $t('inventory.products')" :performanceVisible="performanceVisible"/>
    <Statuscard :title="$t('inventory.inventoryPresent')"
      :ist="inventoryVenueWineState.actualBottleAmount + ' ' + $t('inventory.article')"
      :soll="inventoryVenueWineState.targetBottleAmount + ' ' + $t('inventory.article')"
      :performance="formatNumber(inventoryVenueWineState.actualBottleAmount -  inventoryVenueWineState.targetBottleAmount) + ' ' + $t('inventory.article')" :performanceVisible="performanceVisible"/>
    <Statuscard :title="$t('inventory.valueOfGoods')" :ist="formatAmount(inventoryVenueWineState.totalValue) + ' ' + getCurrency"
      :soll="formatAmount(inventoryVenueWineState.targetValue) + ' ' + getCurrency" :performance="formatNumber(formatAmount(inventoryVenueWineState.targetValue -  inventoryVenueWineState.totalValue)) + ' ' + getCurrency" :performanceVisible="performanceVisible"/>
    </div>
  </div>

</template>

<script>
import Statuscard from '@/components/cards/StatusCard.vue';
import { getVenueCurrency } from '@/utils/functions';
import { mapGetters } from 'vuex';
export default {
  name: 'StatusCards',
  props: {
    inventoryVenueWineState: {
      type: Object,
      required: true
    },
    performanceVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Statuscard
  },
  methods: {
    formatNumber(value) {
      return value >= 1 ? `+${this.value}` : `${value}`;
    },
    formatAmount(value) {
      return value.toLocaleString('de-DE' ,{minimumFractionDigits:2, maximumFractionDigits:2});
    }
  },
  computed: {
    getCurrency() {
      return getVenueCurrency(this.getVenue)
    },
    ...mapGetters(['getVenue'])
  }
}
</script>

<style scoped>

.status-cards {
  display: flex;
  justify-content: left;
}
</style>